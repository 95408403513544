export default function Footer() {
    return (
        <footer className="flex flex-wrap items-center justify-between relative bg-primary px-4 md:px-10 min-h-[60px]">
            <div className="flex flex-wrap items-center justify-between py-4 flex-auto w-full">
                <p className="text-sm hover:text-white sm:text-base text-white"> <span className="font-medium">Contact us:</span>  +61 2 9931 2600</p>
                <p className="text-sm hover:text-white sm:text-base text-white"> <span className="font-medium">Email us:</span><a href="mailto:info@engagetv.com.au"> info@engagetv.com.au</a></p>
            </div>
        </footer>
    )
}
